@media only screen and (max-width: 900px) {
  .DateInput {
    width: 60px
  }
}

.DateInput_input {
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  padding-left: 0px;
}

.DateInput__small {
  margin-right: 5px;
}

.DateInput_input {
  border-bottom: 0px solid rgba(0, 0, 0, 0.87);
  padding-left: 11px;
}

.DateInput_input__focused {
  border-color: #3144BD;
  border-bottom: 0px solid #3144BD;
}

.CalendarDay__selected, .CalendarDay__selected:active, .CalendarDay__selected:hover {
  background: #3144BD;
  border: 1px double #3144BD;
  color: #fff;
}

.CalendarDay__selected_span, .CalendarDay__hovered_span, .CalendarDay__hovered_span:hover {
  background: #AEC1E1;
  border: 1px double #AEC1E1;
  color: #ffffff;
}

.SingleDatePickerInput_calendarIcon {
  margin-left: 0px;
  margin-right: 10px;
  padding: 0px;
}

.datePickerContainer {
  width: calc(100% - 10px);
  border: 1px solid #ffffff;
  height: 50px;
  display: flex;
  background: #ffffff;
  box-shadow: 0px 0px 5px 0px rgba(180, 180, 180, 0.75);
  align-items: center;
  margin-right: 10px;
  border-radius: 5px;
}
.datePickerContainer .SingleDatePicker{
  width: 100%;
  position: relative;
}
.SingleDatePicker_picker {
    top: 45px !important
}
.DateInput_fang {
  top: 35px !important
}
.datePickerContainer .SingleDatePickerInput__showClearDate {
  width: calc(100% - 30px);
}
.datePickerContainer .SingleDatePickerInput {
  border-radius: 10px;
}
.datePickerContainer .DateInput {
  width: 100%;
  border-radius: 10px;
}
.datePickerContainer input {
  width: 100%;
  font-size: 1.4rem;
}