@import '~trix/dist/trix.css';
html {
  font-size: 62.5%;
}

body {
  font-family: "Gothic A1", sans-serif;
  font-size: 1.4rem;
  overflow-y: auto !important;
  padding-right: 0 !important;
}

a {
  outline: 0;
  color: #008cff;
  text-decoration: none;
}

input {
  font-family: "Gothic A1", sans-serif;
}

blockquote {
  border-left: 2px solid #ddd;
  margin-left: 0;
  margin-right: 0;
  padding-left: 10px;
  color: #aaa;
  font-style: italic;
}

button {
  cursor: pointer;
}

button:disabled {
  cursor: default;
}

.is-active {
  font-weight: bold;
}

.editor :global(.public-DraftEditor-content) {
  min-height: 140px;
}

.headerSpacer {
  height: 70px;
}

.pmsProgressSpacer {
  margin-top: 70px;
}

.mainScrollHeight {
  height: calc(100vh - 70px);
}

.showPmsPrgress .pmsProgressSpacer {
  margin-top: 110px;
}
.showPmsPrgress .headerSpacer {
  height: 110px;
}
.showPmsPrgress .mainScrollHeight {
  height: calc(100vh - 110px);
}

.hideScrollBar {
  /* Hide scrollbar for Chrome, Safari and Opera */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.hideScrollBar::-webkit-scrollbar {
  display: none;
}

.country-tile__rooms, .country-tile__articles, .country-tile__activities {
  margin-bottom: 3.2rem;
}

.country-tile {
  padding: 0 5px 0 5px;
}

.country-tile__image {
  background: center center;
  background-size: cover;
  height: 330px;
  margin: 0 auto;
  position: relative;
}

.country-tile__title {
  position: absolute;
  left: 16px;
  bottom: 16px;
  color: #ffffff;
  font-weight: 700;
}

.slick-prev {
  left: -12px;
  z-index: 2;
}

.slick-next {
  right: -12px;
  z-index: 2;
}

.slick-slider .slick-disabled {
  display: none;
}

.slick-prev::before {
  position: absolute;
  left: 0px;
  bottom: 9px;
  margin: auto;
  z-index: 3;
  opacity: 0.5;
  content: url("data:image/svg+xml; utf8, <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 40 40'><path d='M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z'/></svg>");
  -webkit-transform: rotate(180deg); /* Chrome and other webkit browsers */
  -moz-transform: rotate(180deg); /* FF */
  -o-transform: rotate(180deg); /* Opera */
  -ms-transform: rotate(180deg); /* IE9 */
  transform: rotate(180deg);
}

.slick-next::before {
  position: absolute;
  left: 9px;
  bottom: 0px;
  margin: auto;
  z-index: 3;
  opacity: 0.5;
  content: url("data:image/svg+xml; utf8, <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 40 40'><path d='M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z'/></svg>");
}

@media only screen and (max-width: 900px) {
  .DateInput {
    width: 60px;
  }
}
.DateInput_input {
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  padding-left: 0px;
}

.DateInput__small {
  margin-right: 5px;
}

.DateInput_input {
  border-bottom: 0px solid rgba(0, 0, 0, 0.87);
  padding-left: 11px;
}

.DateInput_input__focused {
  border-color: #3144BD;
  border-bottom: 0px solid #3144BD;
}

.CalendarDay__selected, .CalendarDay__selected:active, .CalendarDay__selected:hover {
  background: #3144BD;
  border: 1px double #3144BD;
  color: #fff;
}

.CalendarDay__selected_span, .CalendarDay__hovered_span, .CalendarDay__hovered_span:hover {
  background: #AEC1E1;
  border: 1px double #AEC1E1;
  color: #ffffff;
}

.SingleDatePickerInput_calendarIcon {
  margin-left: 0px;
  margin-right: 10px;
  padding: 0px;
}

.datePickerContainer {
  width: calc(100% - 10px);
  border: 1px solid #ffffff;
  height: 50px;
  display: flex;
  background: #ffffff;
  box-shadow: 0px 0px 5px 0px rgba(180, 180, 180, 0.75);
  align-items: center;
  margin-right: 10px;
  border-radius: 5px;
}

.datePickerContainer .SingleDatePicker {
  width: 100%;
  position: relative;
}

.SingleDatePicker_picker {
  top: 45px !important;
}

.DateInput_fang {
  top: 35px !important;
}

.datePickerContainer .SingleDatePickerInput__showClearDate {
  width: calc(100% - 30px);
}

.datePickerContainer .SingleDatePickerInput {
  border-radius: 10px;
}

.datePickerContainer .DateInput {
  width: 100%;
  border-radius: 10px;
}

.datePickerContainer input {
  width: 100%;
  font-size: 1.4rem;
}

.slick-slide {
  padding: 0 8px;
  box-sizing: border-box;
  outline: none !important;
}

.slick-slide:active {
  outline: none !important;
}

.slick-list {
  height: 100%;
  margin: 0 -8px 0 -8px;
}

.slick-prev {
  left: -10px;
  z-index: 2;
}

.slick-next {
  right: -10px;
  z-index: 2;
}

.slick-slider .slick-disabled {
  display: none;
}

.slick-prev::before {
  position: absolute;
  left: -1px;
  bottom: 8px;
  margin: auto;
  z-index: 3;
  opacity: 0.5;
  content: url("data:image/svg+xml; utf8, <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 40 40'><path d='M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z'/></svg>");
  -webkit-transform: rotate(180deg); /* Chrome and other webkit browsers */
  -moz-transform: rotate(180deg); /* FF */
  -o-transform: rotate(180deg); /* Opera */
  -ms-transform: rotate(180deg); /* IE9 */
  transform: rotate(180deg);
}

.slick-next::before {
  position: absolute;
  left: 8px;
  bottom: 0px;
  margin: auto;
  z-index: 3;
  opacity: 0.5;
  content: url("data:image/svg+xml; utf8, <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 40 40'><path d='M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z'/></svg>");
}

.loadingOverlay {
  background-color: #EFEFEF;
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 10000;
  top: 0px;
  left: 0px;
  opacity: 0.5;
  filter: alpha(opacity=50);
}

.loading {
  top: 50%;
  left: 50%;
  display: inline-block;
  position: relative;
}

.select-up .Select-menu-outer {
  position: absolute !important;
  top: auto !important;
  bottom: calc(100% - 1px) !important;
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  border-top-left-radius: 5px !important;
  border-top-right-radius: 5px !important;
}

.select-up .is-open .Select-control {
  border-top-right-radius: 0 !important;
  border-top-left-radius: 0 !important;
  border-bottom-right-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
}

.attachment--preview {
  margin: 0.6em 0;
  text-align: center;
  width: 100%;
}

.attachment {
  display: inline-block;
  position: relative;
  max-width: 100%;
  margin: 0;
  padding: 0;
}

.tribute-container {
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.1), 0 5px 20px rgba(0, 0, 0, 0.05);
  max-width: unset;
  overflow-y: scroll;
}
.tribute-container ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.tribute-container li {
  background: #fff;
  padding: 0.2em 1em;
  min-width: 15em;
  max-width: 100%;
}
.tribute-container .highlight {
  background: #1b6ac9 !important;
  color: #fff;
}
.tribute-container .highlight span {
  font-weight: bold;
}

.mention img {
  width: 21px;
  height: 21px;
  border-radius: 50%;
  vertical-align: middle;
}
.mention div {
  display: inline-block;
  width: 21px;
  height: 21px;
  border-radius: 50%;
  vertical-align: middle;
}
.mention span {
  font-size: 14px;
  color: #375180;
}
.mention .default-mention-profile-image {
  overflow: hidden;
  position: relative;
  font-size: 1.1rem;
  align-items: center;
  flex-shrink: 0;
  font-family: Roboto, sans-serif;
  line-height: 2;
  border-radius: 50%;
  color: #fafafa;
  background-color: #bdbdbd;
  text-align: center;
}

.mentions-item span {
  display: flex;
  align-items: center;
}
.mentions-item .mentions-profile-image {
  width: 36px;
  height: 36px;
  border-radius: 50%;
}
.mentions-item .mentions-info {
  margin-left: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.mentions-item .mentions-user-name {
  margin-bottom: 2px;
  font-size: 12px;
  font-weight: 500;
}
.mentions-item .mentions-email {
  font-weight: bold;
  font-size: 12px;
}

.default-mention-profile-image {
  width: 40px;
  height: 40px;
  display: flex;
  overflow: hidden;
  position: relative;
  font-size: 1.5rem;
  align-items: center;
  flex-shrink: 0;
  font-family: Roboto, sans-serif;
  line-height: 1;
  user-select: none;
  border-radius: 50%;
  justify-content: center;
  color: #fafafa;
  background-color: #bdbdbd;
}

trix-toolbar .trix-dialog__link-fields {
  flex-wrap: wrap;
  align-items: unset;
}

trix-toolbar {
  border-bottom: 1px solid #dedede;
  padding-bottom: 5px;
}
trix-toolbar .trix-dialog__link-fields {
  flex-wrap: wrap;
  align-items: unset;
}
trix-toolbar .trix-button {
  border: none !important;
  margin-right: 5px;
  height: 24px !important;
}
trix-toolbar .trix-button.trix-button--dialog {
  border-left: 1px solid #ddd !important;
}
trix-toolbar .trix-button-row {
  display: inline-block;
  overflow-x: unset;
}

trix-editor {
  border: unset;
  min-height: 200px;
  padding-top: 15px;
  line-height: 1.6;
}

.trix-content h1 {
  font-size: 2em;
  margin: 0.67em 0;
  line-height: unset;
}

.StripeElement {
  display: block;
  margin-top: 8px;
  padding: 11px 14px;
  font-size: 1em;
  font-family: "Source Code Pro", monospace;
  box-shadow: 0 2px 30px #F0F0F0;
  border: 0;
  outline: 0;
  border-radius: 5px;
}

.StripeElement--invalid {
  border: 1px solid #f44336;
}

.my-masonry-grid {
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;
  margin-left: -30px; /* gutter size offset */
  width: auto;
}

.my-masonry-grid_column {
  padding-left: 30px; /* gutter size */
  background-clip: padding-box;
  box-sizing: border-box;
}

/* Style your items */
.my-masonry-grid_column > div { /* change div to reference your elements you put in <Masonry> */
  margin-bottom: 30px;
}