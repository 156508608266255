//Trix editor css.................
.attachment--preview {
  margin: 0.6em 0;
  text-align: center;
  width: 100%;
}
.attachment {
  display: inline-block;
  position: relative;
  max-width: 100%;
  margin: 0;
  padding: 0;
}

.tribute-container {
  border-radius: 4px;
  border: 1px solid rgba(0,0,0,0.1);
  box-shadow: 0 0 4px rgba(0,0,0,0.1), 0 5px 20px rgba(0,0,0,0.05);
  max-width: unset;
  overflow-y: scroll;

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  li {
    background: #fff;
    padding: 0.2em 1em;
    min-width: 15em;
    max-width: 100%;
  }

  .highlight {
    background: #1b6ac9 !important;
    color: #fff;

    span {
      font-weight: bold;
    }
  }
}

.mention{
  img {
    width: 21px;
    height: 21px;
    border-radius: 50%;
    vertical-align:middle;
  }

  div{
    display: inline-block;
    width: 21px;
    height: 21px;
    border-radius: 50%;
    vertical-align:middle;
  }
  span {
      font-size: 14px;
      color: #375180;
      // margin-left: 3px;
  }

  .default-mention-profile-image{
    overflow: hidden;
    position: relative;
    font-size: 1.1rem;
    align-items: center;
    flex-shrink: 0;
    font-family: Roboto, sans-serif;
    line-height: 2;
    border-radius: 50%;
    color: #fafafa;
    background-color: #bdbdbd;
    text-align: center;

  }
}

.mentions-item{
  span{
    display: flex;
    align-items: center;
  }

  .mentions-profile-image{
    width: 36px;
    height: 36px;
    border-radius: 50%;
  }

  .mentions-info{
    margin-left: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  .mentions-user-name{
    margin-bottom: 2px;
    font-size: 12px;
    font-weight: 500;
  }

  .mentions-email{
    font-weight: bold;
    font-size: 12px;
  }
}

.default-mention-profile-image{
    width: 40px;
    height: 40px;
    display: flex;
    overflow: hidden;
    position: relative;
    font-size: 1.5rem;
    align-items: center;
    flex-shrink: 0;
    font-family: Roboto, sans-serif;
    line-height: 1;
    user-select: none;
    border-radius: 50%;
    justify-content: center;
    color: #fafafa;
    background-color: #bdbdbd;
}

trix-toolbar .trix-dialog__link-fields{
    flex-wrap: wrap;
    align-items: unset;
}

trix-toolbar{
    border-bottom: 1px solid #dedede;
    padding-bottom: 5px;

    & .trix-dialog__link-fields{
        flex-wrap: wrap;
        align-items: unset;
    }

    & .trix-button{
        border: none !important;
        margin-right: 5px;
        height: 24px !important;

        &.trix-button--dialog{
            border-left: 1px solid #ddd !important;
        }
    }

    & .trix-button-row{
        display: inline-block;
        overflow-x: unset;
    }
}

trix-editor{
  border: unset;
  min-height: 200px;
  padding-top: 15px;
  line-height: 1.6;
}

.trix-content h1{
  font-size: 2em;
  margin: 0.67em 0;
  line-height: unset;
}