.StripeElement {
    display: block;
    margin-top: 8px;
    padding: 11px 14px;
    font-size: 1em;
    font-family: "Source Code Pro", monospace;
    box-shadow: 0 2px 30px #F0F0F0;
    border: 0;
    outline: 0;
    border-radius: 5px;
    // background: white;
}

.StripeElement--invalid{
    border: 1px solid #f44336;
}