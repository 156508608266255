html {
  font-size: 62.5%;
}

body {
  font-family: "Gothic A1", sans-serif;
  font-size: 1.4rem;
  overflow-y: auto !important;
  padding-right: 0 !important;
}

a {
  outline: 0;
  color: #008cff;
  text-decoration: none;
}

input {
  font-family: "Gothic A1", sans-serif;
}

// ul {
//   margin: 0;
//   padding: 0;
// }

// li {
//   list-style: none;
// }

blockquote {
  border-left: 2px solid #ddd;
  margin-left: 0;
  margin-right: 0;
  padding-left: 10px;
  color: #aaa;
  font-style: italic;
}

button {
  cursor: pointer;
}

button:disabled {
  cursor: default;
}

.is-active {
  font-weight: bold;
}

.editor :global(.public-DraftEditor-content) {
  min-height: 140px;
}

.headerSpacer{
  height: 70px;
}

.pmsProgressSpacer{
  margin-top: 70px;
}

.mainScrollHeight{
  height: calc(100vh - 70px);
}

.showPmsPrgress{

  .pmsProgressSpacer{
    margin-top: 110px;
  }

  .headerSpacer{
    height: 110px;
  }

  .mainScrollHeight{
    height: calc(100vh - 110px);
  }
}

//Hide scrollbar ....
.hideScrollBar{
  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none
  }
  
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

