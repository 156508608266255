
// Slick Carousel Styling
// .slick-slide {
  // padding-left: 1rem;
  // padding-right: 1rem;
// }

// .slick-track {
//   display: flex;
//   align-items: center;
// }

.slick-slide {
  padding: 0 8px;
  box-sizing: border-box;
  outline: none !important;
}

.slick-slide:active {
  outline: none !important;
}

.slick-list {
  height: 100%;
  margin: 0 -8px 0 -8px;
}

.slick-prev {
  left: -10px;
  z-index: 2;
}

.slick-next {
  right: -10px;
  z-index: 2;
}

.slick-slider .slick-disabled {
  display: none;
}

.slick-prev::before {
  position: absolute;
  left: -1px;
  bottom: 8px;
  margin: auto;
  z-index: 3;
  opacity: 0.5;
  content: url("data:image/svg+xml; utf8, <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 40 40'><path d='M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z'/></svg>");
  -webkit-transform: rotate(180deg);     /* Chrome and other webkit browsers */
  -moz-transform: rotate(180deg);        /* FF */
  -o-transform: rotate(180deg);          /* Opera */
  -ms-transform: rotate(180deg);         /* IE9 */
  transform: rotate(180deg); 
}
.slick-next::before {
  position: absolute;
  left: 8px;
  bottom: 0px;
  margin: auto;
  z-index: 3;
  opacity: 0.5;
  content: url("data:image/svg+xml; utf8, <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 40 40'><path d='M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z'/></svg>");
}