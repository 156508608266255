.country-tile__rooms, .country-tile__articles, .country-tile__activities {
    margin-bottom: $l-size;
}

.country-tile {
    padding: 0 5px 0 5px;
}

.country-tile__image {
    background: center center;
    background-size: cover;
    height: 330px;
    margin: 0 auto;
    position: relative;
}

.country-tile__title {
    position: absolute;
    left: 16px;
    bottom: 16px;
    color: #ffffff;
    font-weight: 700;
}

// #country-tile__image-1 { background-image: url('/images/India 1.png');}
// #country-tile__image-2 { background-image: url('/images/Ireland 1.png'); }
// #country-tile__image-3 { background-image: url('/images/Germany 1.png'); }
// #country-tile__image-4 { background-image: url('/images/Thailand 1.png'); }
// #country-tile__image-5 { background-image: url('/images/Nepal 1.png'); }
// #country-tile__image-6 { background-image: url('/images/England 1.png'); }
// #country-tile__image-7 { background-image: url('/images/Italy 1.png'); }

// .slick-slide {
//     padding-left: 0px;
//     padding-right: 0px;
// }

// .slick-slide{
//     margin-left:  15px;
//     margin-right:  15px;
// }

// .slick-list {
// margin-left: -15px;
// margin-right: -15px;
// }

.slick-prev {
    left: -12px;
    z-index: 2;
}
.slick-next {
    right: -12px;
    z-index: 2;
}
.slick-slider .slick-disabled {
    display: none;
}
.slick-prev::before {
    position: absolute;
    left: 0px;
    bottom: 9px;
    margin: auto;
    z-index: 3;
    opacity: 0.5;
    content: url("data:image/svg+xml; utf8, <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 40 40'><path d='M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z'/></svg>");
    -webkit-transform: rotate(180deg);     /* Chrome and other webkit browsers */
    -moz-transform: rotate(180deg);        /* FF */
    -o-transform: rotate(180deg);          /* Opera */
    -ms-transform: rotate(180deg);         /* IE9 */
    transform: rotate(180deg); 
}
.slick-next::before {
    position: absolute;
    left: 9px;
    bottom: 0px;
    margin: auto;
    z-index: 3;
    opacity: 0.5;
    content: url("data:image/svg+xml; utf8, <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 40 40'><path d='M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z'/></svg>");
}