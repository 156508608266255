@import url('https://fonts.googleapis.com/css?family=Gothic+A1:300,400,500,700');
@import url('https://fonts.googleapis.com/css?family=Abril+Fatface:300,400,500,700');
@import url('https://fonts.googleapis.com/css?family=Oswald:300,400,500,700');

body {
  margin: 0;
  padding: 0;
  font-family: "Didact Gothic", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.scroll-hide ul::-webkit-scrollbar { 
  width: 0 !important;
  display: none; 
}
