.loadingOverlay {
  background-color: #EFEFEF;
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 10000;
  top: 0px;
  left: 0px;
  opacity: .5;
  filter: alpha(opacity=50)
}

.loading {
  top: 50%;
  left: 50%;
  display: inline-block;
  position: relative
}