.select-up .Select-menu-outer {
  position: absolute !important;
  top: auto !important;
  bottom: calc(100% - 1px) !important;
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  border-top-left-radius: 5px !important;
  border-top-right-radius: 5px !important;
}

.select-up .is-open .Select-control {
  border-top-right-radius: 0 !important;
  border-top-left-radius: 0 !important;
  border-bottom-right-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
}